import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';

const mapActionsToProps: IComponentController = ({ updateProps }) => ({
  onSelectedOptionChange: (optionValue: string) => {
    updateProps({
      value: optionValue,
    });
  },
  setValidityIndication: (shouldShowValidityIndication: boolean) => {
    updateProps({
      shouldShowValidityIndication,
    });
  },
  onFilterQueryChange: (filterQuery: string) => {
    updateProps({ filterQuery });
  },
  onIsExpandedChange: (isExpanded: boolean) => {
    updateProps({ isExpanded });
  },
});

export default mapActionsToProps;
